.btn {
    @include border-radius(26px);
    @include flex();

    align-items: center;
    justify-content: center;
    width: 100%;
    height: 52px;
    line-height: 52px;
    border: none;
    cursor: pointer;
    color: #FFF;
    padding: 0px 24px;
    background: $black;
    text-align: center;
    font-size: sizing($font, default, sm);
    font-weight: bold;
    letter-spacing: 0.5px;

    mat-spinner {
        circle {
            stroke: #FFF !important;
        }
    }

    &:hover {
        background: $soft-black;
    }
}

.btn--green {
    @extend .btn;

    color: $white;
    background: $green;

    &:hover {
        background: $soft-green;
    }
}