@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~normalize.css";
@import "styles/colors.scss";
@import "styles/sizings.scss";
@import "styles/utils.scss";

* {
    font-family: "Varela Round", sans-serif;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    color: #222;
    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

button,
select,
input,
a,
::ng-deep input,
::ng-deep select,
::ng-deep button {
    font-size: sizing($font, default, sm) !important;
    font-family: 'Varela Round', sans-serif;
    outline: none !important;
}

::placeholder {
    color: #C7A9C3;
    opacity: 1;
}

::-ms-input-placeholder {
    color: #C7A9C3;
}

input:focus {
    outline: none !important;
    
}

html,
body {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
}

h1, h2 {
    font-size: sizing($font, default, xl);
    margin-top: 48px;
    padding: 0px 24px;
    text-align: center;

    @include media-sm {
        margin-top: 32px;
        font-size: sizing($font, mobile, xl);
    }
}

h3 {
    text-align: center;
    font-size: sizing($font, default, md);
    font-weight: bold;
    color: $pink;
    margin: 0px;
    padding: 0px 24px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 70px;
    width: 100%;
}

.cdk-overlay-dark-backdrop {
    background: rgba(82, 64, 102, 0.4);
}

.mdc-dialog__title {
    @include flex();

    align-items: center;
    margin: 0px;
    padding: 0px 24px !important;
    border-bottom: 1px solid #EFEFEF;
    font-weight: bold !important;
    font-size: 18px !important;
    letter-spacing: -0.5px !important;
    color: #222222 !important;
    height: 64px !important;
    font-family: "Varela Round", sans-serif !important;
    letter-spacing: 0.5px !important;
}

.mdc-dialog__title::before {
    display: none !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    @include border-radius(8px);

    box-shadow: rgba(82, 64, 102, 0.5) 0px 7px 29px 0px !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    padding-top: 24px !important;
}

.mat-mdc-dialog-actions {
    @include flex();

    justify-content: space-between !important;
    height: 64px !important;
    padding: 0px 24px !important;
    border-top: 1px solid #CCC !important;
}

.mdc-checkbox__background {
    background: $white !important;
    border-color: #D5DEE3 !important;
}

.error {
    margin-top: 4px;
    color: #FF4444;
    text-align: left;
    font-size: sizing($font, default, xs);
    line-height: sizing($font, lineHeight, xs);
}

.desktop {
    display: block !important;
}

.mobile {
    display: none !important;
}

@import "./classes/button.scss";

@include media-sm {
    .desktop {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }

    .cdk-overlay-pane {
        width: 100% !important;
        max-width: 100% !important;
        margin: 0px 24px !important;
    }
}
